.navbar-mobile {
    height:0;
    transition: height 300ms ease;
    &.active {
        height:112px;
    }
    .navbar-nav{
        li {
            a {
                &:hover {
                    color:#f59f13 !important;
                }
                &:focus {
                    color:#f59f13 !important;
                }
            }
        }
    }
}
