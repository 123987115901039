@import url("https://fonts.googleapis.com/css?family=Montserrat:400&display=swap");
@import url("https://fonts.googleapis.com/css?family=Work+Sans:700&display=swap");
@import './cinesaga';
@import './header';
body{
    font-family:Montserrat;
    font-weight:400;
}
body h1,body h2,body h3,body h4,body h5,body h6{
    font-family:Work Sans;
    font-weight:700;
}
a {
    color:#f7b344;
    &:hover {
        color:#f59f13;
    }
}
.btn-primary {
    background-color:#f7b344;
    &:hover {
        background-color:#f59f13;
    }
}
.calc-container {
  min-width: 100vw;
  min-height: 100vh;
  background-color: #05183f;
}
.modal-body {
  position: relative;
  padding: 10px;
  display: flex;
  flex-direction: column;
}
.modal-header {
    padding:10px;
    
    font-family: 'Work Sans';
    font-weight: 700;
    .modal-title{
        color:#5e6971;
    }
    
}

.modal {
  display: block;
  position: initial;

  .modal-dialog {
    width: 85%;
    max-width: 960px;
    margin:10px auto;
    @media only screen 
    and (min-device-width: 320px) 
    and (max-device-width: 667px) 
    and (-webkit-min-device-pixel-ratio: 2) { 
        width: 95%;
    }
  }

  .modal-sub-title {
        font-size:18px;
        font-family: Montserrat;
        padding:10px 20px 0 20px;
        text-align: center;
        line-height:24px;
        font-weight: 100;
        span {
            font-family: Montserrat;
            font-style: italic;
            font-weight: 100;
            font-size: 16px;
            display: inline-block;
            padding-top:5px;
        }
  }
}

.input-boxes {
    display: flex;
    justify-content: space-evenly;
    div {
        max-width: 150px;
    }
    h3 {
        font-family: 'Work Sans';
        font-weight: 700;
        color:#5e6971;
        text-transform: capitalize;
        text-align: center;
        font-size: 14px;
    }

    input {
        margin:0 5px 0 0;
        text-align: center;
        display: inline-block;
        width:80px;
        padding:5px 0 5px 15px;
        height: 30px;
        @media only screen 
        and (min-device-width: 320px) 
        and (max-device-width: 667px) 
        and (-webkit-min-device-pixel-ratio: 2) { 
            width: 60px;
            padding:5px 0 5px 0px;
        }
    }
}

table {
    margin-top:30px;
    width:100%;
    tr {
        border:1px solid #e0e7ed;
        td {
            padding:10px 0;
        }
    }
}
thead {
    background-color: #f7f8fc;
    border: 1px solid #e0e7ed;
    border-radius: 5px;
    th {
        padding:10px 0;
        font-family: Work Sans;
        font-weight: 700;
        text-transform: capitalize;
        text-align: center;
        font-size:14px;
        width:30%;
    }
   
}

tbody{
    background-color: white;
    border: 1px solid #e0e7ed;
    border-radius: 5px;
    text-align: center;
    td{
        font-size:14px;
    }
    tr:nth-of-type(even) {
        background: #f7f8fc;
      }
      
    input.form-control {
        margin:0 5px 0 0;
        text-align: center;
        display: inline-block;
        width:80px;
        padding:5px auto;
        height: 30px;
        @media only screen 
        and (min-device-width: 320px) 
        and (max-device-width: 667px) 
        and (-webkit-min-device-pixel-ratio: 2) { 
            width: 60px;
        }
    }
}

.inputs {
    display:none;
    justify-content: center;
    
    &.active {
        display: flex;
        
    }

    label {
        display: flex;
        flex-direction: column;
        text-align: center;
        font-size:12px;
        color:#5e6971;

    }

    input {
        font-family:Montserrat;
        font-weight:400;
        color:#5e6971;
        border-color: #f7b344;
        transition: background-color 200ms ease;
        &:focus {
            border-color: #f7b344;
            background-color: #fff;
            box-shadow:inset 0 0 5px 5px ;
        }
    }
}

.distance {
    display: flex;
    justify-content: center;
    background-color: #f7f8fc;
    border: 1px solid #e0e7ed;
    border-radius: 5px;
    align-self: center;
    position: relative;
    z-index: 2;
    overflow: hidden;
    font-size:16px;
    margin-top:20px;
    font-family: Work Sans;
    font-weight: 700;
    .measure {
        width:100px;
        text-align: center;
    }
    cursor:pointer;
    span {
        z-index: 2;
        line-height:35px;
        margin: 0 5px 0 5px;
        font-weight: bold;
    }

    .selected {
        display: inline-block;
        background-color: #f7b344;
        height: 40px;
        width: 113px;
        position: absolute;
        box-sizing: border-box;
        left: 0px;
        z-index: 1;
        margin: 0;
        transition: left 200ms ease-in-out;
        &.metres {
            height: 40px;
            width: 113px;
            left: 50%;
        }
    }
}

.footer-text {
    font-size: 18px;
    margin-top:30px;
    line-height:24px;
    text-align: center;
    padding:0 20px 0 20px;
}
